<!--  -->
<template>
    <div class=''>

        <div>
            <el-col :span="5">
                <div class="calender-scroll" align="center">
                    <!-- <div id="TendencySingoCalendar" :style="'width:220px;height:1200px'"> -->
                    <div id="TendencySingoCalendar" :style="'width:220px;height:1200px'">
                    </div>
                </div>
            </el-col>
            <el-col :span="19">
                <el-col :span="2">
                    <div class="blank"></div>
                </el-col>
                <el-button-group>
                    <el-button size="small" autofocus="true" @click="getWeekDay()">本周</el-button>
                    <el-button size="small" @click="getMonth()">本月</el-button>
                    <el-button size="small" @click="getYear()">本年</el-button>
                </el-button-group>

                <!-- <div class="mr20" v-for="(item, index) in bdTimeData" :key="index" @click="handleBdTime(index)"
                    :class="{ timeStyle: timeIndex == index }">{{ item.title }}
                            </div> -->

                <div align="center">
                    <div id="TendencySingoChart" :style="'width:' + (screenWidth * 19 / 24 - 320) + 'px; height:550px'">
                    </div>
                </div>
            </el-col>
        </div>
    </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

import * as echarts from 'echarts';
export default {
    //import引入的组件需要注入到对象中才能使用
    components: {},
    data() {
        //这里存放数据
        return {
            screenHeight: window.innerHeight,
            screenWidth: window.innerWidth,
            formerday: '',
            latterday: '',
            bdTimeIndex: "1",//默认显示本周数据
            bdScopeTime: "month",
            bdTimeData: [
                { title: "本周", value: "week" },
                { title: "本月", value: "month" },
                { title: "全年", value: "year" }
            ],
            option: {},
            Calendar_option: {},
        };
    },
    //监听属性 类似于data概念
    computed: {},
    //监控data中的数据变化
    watch: {},
    //方法集合
    methods: {
        TimeProcess(time) {
            let y = time.getFullYear();
            let MM = time.getMonth() + 1;
            MM = MM < 10 ? ('0' + MM) : MM;
            let d = time.getDate();
            d = d < 10 ? ('0' + d) : d;
            return y + '-' + MM + '-' + d
        },
        handleBdTime(index) {
            this.bdTimeIndex = index;
            this.bdScopeTime = value
            if (bdTimeIndex == 0) {
                this.getWeekDay()
            }
            else if (bdTimeIndex == 1) {
                this.getMonth()
            }
            else {
                this.getYear()
            }
        },
        getWeekDay() {
            var today = new Date();
            var formerday = new Date(today);
            formerday.setDate(today.getDate() - 6);
            this.latterday = this.TimeProcess(today)
            this.formerday = this.TimeProcess(formerday)
            console.log(this.formerday)
            console.log(this.latterday)
            this.title = '本周'
            this.initSingo()
            this.UpdateSingo_getConclusion(this.formerday, this.latterday)
        },
        getMonth() {
            // 本月指的是近30天的趋势
            var date = new Date()
            var MonthfirstDay = new Date(date);
            MonthfirstDay.setDate(date.getDate() - 30);
            this.latterday = this.TimeProcess(date)
            this.formerday = this.TimeProcess(MonthfirstDay)
            console.log(this.formerday)
            console.log(this.latterday)
            this.title = '本月'
            // this.getConclusion(this.formerday, this.latterday)
            this.initSingo()
            this.UpdateSingo_getConclusion(this.formerday, this.latterday)
        },
        getYear() {
            var date = new Date(), y = date.getFullYear();
            var YearfirstDay = new Date(y, 0);
            var YearlastDay = new Date(y + 1, 0, 0);
            this.latterday = this.TimeProcess(YearlastDay)
            this.formerday = this.TimeProcess(YearfirstDay)
            console.log(this.formerday)
            console.log(this.latterday)
            this.title = '本年'
            this.initSingo()
            this.UpdateSingo_getConclusion(this.formerday, this.latterday)
        },
        initSingo() {
            this.myChart = this.$echarts.init(document.getElementById("TendencySingoChart"));
            this.myChart.setOption(this.option);
            this.myChart.showLoading({
                text: '请稍等, 正在为您加载数据',
                zlevel: 0,
                // 字体大小。
                fontSize: 20,
                // 是否显示旋转动画（spinner）。
                showSpinner: true,
                // 旋转动画（spinner）的半径。
                spinnerRadius: 16,
                // 旋转动画（spinner）的线宽。
                lineWidth: 8,
            });
        },

        initCalendar() {
            this.CalendarChart = this.$echarts.init(document.getElementById("TendencySingoCalendar"));
            this.CalendarChart.setOption(this.Calendar_option)
            this.CalendarChart.showLoading({
                text: '',
                zlevel: 0,
                // 字体大小。
                fontSize: 20,
                // 是否显示旋转动画（spinner）。
                showSpinner: true,
                // 旋转动画（spinner）的半径。
                spinnerRadius: 16,
                // 旋转动画（spinner）的线宽。
                lineWidth: 8,
            });
        },
        getVirtualData(year) {
            const date = +echarts.time.parse(year + '-01-01');
            const end = +echarts.time.parse(year + '-12-31');
            const dayTime = 3600 * 24 * 1000;
            const data = [];
            for (let time = date; time < end; time += dayTime) {
                data.push([
                    echarts.time.format(time, '{yyyy}-{MM}-{dd}', false),
                    Math.floor(Math.random() * 10000)
                ]);
            }
            return data;
        },
        UpdateSingo_getConclusion(former, latter) {
            // 根据uid、起止日期、返回所有静态心电结论
            this.$http({
                url: this.$http.adornUrl("/personal/staticecg/getConclusionByTime"),
                method: "post",
                data: this.$http.adornData({
                    uid: this.$store.state.women_baby.uid, startTime: former, endTime: latter
                }),
            }).then(({ data }) => {
                if (data && data.code === 0) {
                    console.log("看看conclusion");
                    console.log(data);
                    this.conclusionData = data.conclusions
                    if (this.conclusionData[this.conclusionData.length - 1] == ',') {
                        this.conclusionData = "[" + this.conclusionData.substring(0, this.conclusionData.length - 1) + "]";
                    }//JSON格式转换

                    this.xinlv = new Array(this.conclusionData.length)
                    this.xdgs = new Array(this.conclusionData.length)
                    this.xdgh = new Array(this.conclusionData.length)
                    this.xlbq = new Array(this.conclusionData.length)
                    this.dxtb = new Array(this.conclusionData.length)
                    this.fxzb = new Array(this.conclusionData.length)
                    this.jjxzb = new Array(this.conclusionData.length)
                    this.sxzb = new Array(this.conclusionData.length)
                    this.ssxxdgs = new Array(this.conclusionData.length)
                    this.sxxdgs = new Array(this.conclusionData.length)
                    this.fxyb = new Array(this.conclusionData.length)
                    this.sxyb = new Array(this.conclusionData.length)
                    this.fc = new Array(this.conclusionData.length)
                    this.timelist = new Array(this.conclusionData.length)
                    this.conclusion = new Array(this.conclusionData.length)
                    for (let i = 0; i < this.conclusion.length; i++) {
                        this.conclusion[this.conclusionData.length - 1 - i] = JSON.parse(this.conclusionData[i])
                    }
                    for (let i = 0; i < this.conclusion.length; i++) {
                        this.timelist[i] = this.conclusion[i].date
                        this.xinlv[i] = this.conclusion[i].xinlv
                        this.xdgs[i] = this.conclusion[i].xdgs
                        this.xdgh[i] = this.conclusion[i].xdgh
                        this.xlbq[i] = this.conclusion[i].xlbq
                        this.dxtb[i] = this.conclusion[i].dxtb
                        this.fxzb[i] = this.conclusion[i].fxzb
                        this.jjxzb[i] = this.conclusion[i].jjxzb
                        this.sxzb[i] = this.conclusion[i].sxzb
                        this.ssxxdgs[i] = this.conclusion[i].ssxxdgs
                        this.sxxdgs[i] = this.conclusion[i].sxxdgs
                        this.fxyb[i] = this.conclusion[i].fxyb
                        this.sxyb[i] = this.conclusion[i].sxyb
                        this.fc[i] = this.conclusion[i].fc
                    }

                    // 更新echarts数据
                    // 先清空之前的
                    this.option.title.text = [];
                    this.option.xAxis[0].data = [];
                    this.option.series[0].data = [];
                    this.option.series[1].data = [];
                    this.option.series[2].data = [];
                    this.option.series[3].data = [];
                    this.option.series[4].data = [];
                    this.option.series[5].data = [];
                    this.option.series[6].data = [];
                    this.option.series[7].data = [];
                    this.option.series[8].data = [];
                    this.option.series[9].data = [];
                    this.option.series[10].data = [];
                    this.option.series[11].data = [];
                    this.option.series[12].data = [];

                    // 再设置新的
                    if (this.conclusionData.length != 0) {
                        this.option.title.text.push(this.title + '趋势图')
                        this.option.title.y = 'top'
                        this.option.xAxis[0].data = this.timelist.map(function (str) {
                            return str.replace(' ', '\n');
                        })
                        this.option.series[0].data = this.xinlv;
                        this.option.series[1].data = this.xdgs;
                        this.option.series[2].data = this.xdgh;
                        this.option.series[3].data = this.xlbq;
                        this.option.series[4].data = this.dxtb;
                        this.option.series[5].data = this.fxzb;
                        this.option.series[6].data = this.jjxzb;
                        this.option.series[7].data = this.sxzb;
                        this.option.series[8].data = this.ssxxdgs;
                        this.option.series[9].data = this.sxxdgs;
                        this.option.series[10].data = this.jjxzb;
                        this.option.series[11].data = this.sxyb;
                        this.option.series[12].data = this.fc;
                    }
                    else {
                        this.option.title.text.push('暂无数据')
                        this.option.title.x = 'center'
                        this.option.title.y = 'center'
                        // this.option = {
                        //     title: {
                        //         text: '暂无数据',
                        //         x: 'center',
                        //         y: 'center',
                        //         textStyle: {
                        //             fontSize: 14,
                        //             fontWeight: 'normal',
                        //         }
                        //     }
                        // }
                    }
                    this.myChart.hideLoading();
                    this.myChart.setOption(this.option);

                } else {
                    // this.getCaptcha()
                    this.$message.error(data.msg);
                }
            });

        },
        // querylistByTime() {
        //     var date = new Date(), y = date.getFullYear();
        //     var YearfirstDay = new Date(y, 0);
        //     var YearlastDay = new Date(y + 1, 0, 0);
        //     this.YearlastDay = this.TimeProcess(YearlastDay)
        //     this.YearfirstDay = this.TimeProcess(YearfirstDay)
        //     this.$http({
        //         url: this.$http.adornUrl("/personal/staticecg/listByTime"),
        //         method: "get",
        //         params: this.$http.adornParams({ uid: this.$store.state.women_baby.uid, startTime: this.YearfirstDay, endTime: this.YearlastDay }),
        //     }).then(({ data }) => {
        //         if (data && data.code === 0) {
        //             console.log("看看数据");
        //             // console.log(data);
        //             this.tableData = data.staticEcgEntities;
        //             this.calendartime = new Array(this.tableData.length)
        //             for (let i = 0; i < this.tableData.length; i++) {
        //                 this.calendartime[i] = this.tableData[i].createTime.substring(0, 10)
        //             }
        //             // 统计相同日期的个数
        //             var objGroup = this.calendartime.reduce(function (obj, name) {
        //                 obj[name] = obj[name] ? ++obj[name] : 1;
        //                 return obj
        //             }, {});
        //             this.arrayCalendar = Object.entries(objGroup); //对象转化为数组

        //             console.log(this.arrayCalendar)
        //             var CalenderlastMonth = this.arrayCalendar
        //             CalenderlastMonth = CalenderlastMonth[0][0].substring(5, 7)

        //             var CalenderlastMonthDay = new Date(2022, CalenderlastMonth, 0);
        //             this.CalenderlastMonthDay = this.TimeProcess(CalenderlastMonthDay)
        //             this.Calendar()
        //         } else {
        //             // this.getCaptcha()
        //             this.$message.error(data.msg);
        //         }
        //     });
        // },
        Calendar_queryCount() {
            var date = new Date(), y = date.getFullYear();
            var YearfirstDay = new Date(y, 0);
            var YearlastDay = new Date(y + 1, 0, 0);
            this.YearlastDay = this.TimeProcess(YearlastDay)
            this.YearfirstDay = this.TimeProcess(YearfirstDay)
            this.$http({
                url: this.$http.adornUrl("/personal/staticecg/getConclusionCountByTime"),
                method: "post",
                data: this.$http.adornData({
                    uid: this.$store.state.women_baby.uid, startTime: this.YearfirstDay, endTime: this.YearlastDay
                    // uid: this.$store.state.women_baby.uid, startTime: '2022-06-19', endTime: '2022-06-21'
                }),
            }).then(({ data }) => {
                if (data && data.code === 0) {
                    console.log("看看数据");
                    console.log(data)
                    this.CalendarCount = data.conclusions  // 一整条记录
                    console.log(JSON.parse(this.CalendarCount[0]))
                    var score_abnormal = new Array(this.CalendarCount.length)
                    var score_abnormal = new Array(this.CalendarCount.length)
                    var createTime = new Array(this.CalendarCount.length)
                    var score_time = new Object();
                    var all_score_time = []
                    for (let i = 0; i < this.CalendarCount.length; i++) {
                        var score_time = new Object();
                        var thisCalendarCount = JSON.parse(this.CalendarCount[i])
                        score_abnormal[i] = Number(thisCalendarCount.xdgs) + Number(thisCalendarCount.xdgh)
                            + Number(thisCalendarCount.xlbq) + Number(thisCalendarCount.dxtb) + Number(thisCalendarCount.fxzb)
                            + Number(thisCalendarCount.jjxzb) + Number(thisCalendarCount.sxzb) + Number(thisCalendarCount.ssxxdgs)
                            + Number(thisCalendarCount.sxxdgs) + Number(thisCalendarCount.fxyb) + Number(thisCalendarCount.sxyb)
                            + Number(thisCalendarCount.fc)
                        createTime[i] = thisCalendarCount.date.substring(0, 10)
                        score_time.time = createTime[i]
                        score_time.score = score_abnormal[i]
                        all_score_time.push(score_time)
                    }
                    // console.log(all_score_time)
                    // merge代表合并的time与异常值求和
                    var merge = all_score_time.reduce((obj, item) => {
                        let find = obj.find(i => i.time === item.time)
                        let _d = {
                            ...item,
                            count: 1
                        }
                        find ? (find.score += item.score, find.count++) : obj.push(_d)
                        return obj
                    }, [])
                    console.log(merge)
                    // avg_merge代表time与异常值求平均
                    var avg_merge = Object.keys(merge).map(function (k) {
                        const item = merge[k];
                        return {
                            time: item.time,
                            count: item.count,
                            avgScore: item.score / item.count,
                        }
                    })
                    var len = avg_merge.length
                    var CalendarFrequent = []
                    var CalendarAbnormal = []
                    for (let i = 0; i < len; i++) {
                        CalendarFrequent.push([avg_merge[i].time, avg_merge[i].count])
                        CalendarAbnormal.push([avg_merge[i].time, avg_merge[i].avgScore])
                    }
                    this.CalendarFrequent = CalendarFrequent
                    this.CalendarAbnormal = CalendarAbnormal
                    console.log(CalendarAbnormal)
                    // var CalenderlastMonth = this.CalendarFrequent[0][0].substring(5, 7)// 需要确定一下数据库的时间，正确的应该是this.CalendarFrequent[0][0]
                    // var CalenderlastMonthDay = new Date(2022, CalenderlastMonth, 0);
                    // this.CalenderlastMonthDay = this.TimeProcess(CalenderlastMonthDay)

                    // 更新参数
                    this.Calendar_option.series[0].data = []
                    this.Calendar_option.series[1].data = []
                    this.Calendar_option.series[2].data = []

                    this.Calendar_option.series[0].data = this.CalendarFrequent
                    this.Calendar_option.series[1].data = this.CalendarAbnormal
                    this.Calendar_option.series[2].data = this.getVirtualData(2023)
                    this.CalendarChart.hideLoading()
                    this.CalendarChart.setOption(this.Calendar_option);

                } else {
                    // this.getCaptcha()
                    this.$message.error(data.msg);
                }
            });
        }
    },
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {
        // 默认加载本周数据
        // this.getWeekDay()
        var today = new Date()
        this.year = today.getFullYear()
        this.cellSize =[22,20]
        this.option = {
            animation: false,
            title: {
                text: '',
                x: 'center',
                y: 'top',
                textStyle: {
                    fontSize: 15
                }
            },
            grid: {
                top: 70,
                bottom: 80,
                left: 45,
                right: 50
            },
            toolbox: {
                feature: {
                    restore: {},
                    saveAsImage: {},
                    magicType: {
                        type: ['line', 'bar', 'stack'],
                        seriesIndex: {
                            line: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
                            bar: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
                            stack: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
                        }

                    }
                }
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow',
                    animation: false,
                    label: {
                        backgroundColor: '#505765'
                    }
                }
            },
            legend: {
                data: ['心率', '心动过速', '心动过缓', '心律不齐', '窦性停搏', '房性早搏', '交界性早搏', '室性早搏', '室上性心动过速', '室性心动过速', '交界性逸博', '室性逸博', '房颤'],
                left: 'center',
                width: '70%',
                top: 20
            },
            dataZoom: [
                {
                    show: true,
                    realtime: true,
                    start: 0,
                    end: 100
                },
                {
                    type: 'inside',
                    realtime: true,
                    // start: 65,
                    // end: 85
                }
            ],
            xAxis: [
                {
                    type: 'category',
                    boundaryGap: false,
                    axisLine: { onZero: false, },
                    // prettier-ignore
                    data: '',
                }
            ],
            yAxis: [
                {
                    name: '心率(次/分钟)',
                    type: 'value',
                },
                {
                    name: '异常项疑似度(%)',
                    alignTicks: true,
                    type: 'value',
                }
            ],
            series: [
                {
                    name: '心率',
                    type: 'line',
                    color: 'firebrick',
                    label: {
                        show: true
                    },
                    // areaStyle: {},
                    lineStyle: {
                        width: 3
                    },
                    symbol: 'triangle',
                    symbolSize: 10,
                    emphasis: {   //折线图的高亮状态。
                        focus: 'series'
                    },
                    markArea: {
                        silent: true,
                        itemStyle: {
                            opacity: 0.3
                        },
                        data: [
                            [
                                {
                                    yAxis: 100
                                },
                                {
                                    yAxis: 200
                                }
                            ],

                        ]
                    },
                    // prettier-ignore
                    data: []
                },
                {
                    name: '心动过速',
                    type: 'bar',
                    stack: 'total',
                    // color: '#66b7f3 ',
                    yAxisIndex: 1,
                    // areaStyle: {},
                    lineStyle: {
                        width: 1
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    // prettier-ignore
                    data: [],
                    lineStyle: {
                        width: 2
                    },
                },
                {
                    name: '心动过缓',
                    type: 'bar',
                    stack: 'total',
                    // color: '#0c84c6',
                    yAxisIndex: 1,
                    // areaStyle: {},
                    lineStyle: {
                        width: 1
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: [],
                    lineStyle: {
                        width: 2
                    },
                },
                {
                    name: '心律不齐',
                    type: 'bar',
                    stack: 'total',
                    // color: '#2455a4 ',
                    yAxisIndex: 1,
                    // areaStyle: {},
                    lineStyle: {
                        width: 1
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: [],
                    lineStyle: {
                        width: 2
                    },
                },
                {
                    name: '窦性停搏',
                    type: 'bar',
                    stack: 'total',
                    yAxisIndex: 1,
                    // color: ' #D2B4DE  ',
                    // areaStyle: {},
                    lineStyle: {
                        width: 1
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: [],
                    lineStyle: {
                        width: 2
                    },
                },
                {
                    name: '房性早搏',
                    type: 'bar',
                    stack: 'total',
                    yAxisIndex: 1,
                    // color: ' #76448A   ',
                    // areaStyle: {},
                    lineStyle: {
                        width: 1
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: [],
                    lineStyle: {
                        width: 2
                    },
                },
                {
                    name: '交界性早搏',
                    type: 'bar',
                    stack: 'total',
                    // color: '#F5B7B1 ',
                    yAxisIndex: 1,
                    // areaStyle: {},
                    lineStyle: {
                        width: 1
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: [],
                    lineStyle: {
                        width: 2
                    },
                },
                {
                    name: '室性早搏',
                    type: 'bar',
                    stack: 'total',
                    // color: '#CB4335   ',
                    yAxisIndex: 1,
                    // areaStyle: {},
                    lineStyle: {
                        width: 1
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: [],
                    lineStyle: {
                        width: 2
                    },
                },
                {
                    name: '室上性心动过速',
                    type: 'bar',
                    stack: 'total',
                    // color: '#F1C40F ',
                    yAxisIndex: 1,
                    // areaStyle: {},
                    lineStyle: {
                        width: 1
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: [],
                    lineStyle: {
                        width: 2
                    },
                },
                {
                    name: '室性心动过速',
                    type: 'bar',
                    stack: 'total',
                    // color: '#d45e7e',
                    yAxisIndex: 1,
                    // areaStyle: {},
                    lineStyle: {
                        width: 1
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: [],
                    lineStyle: {
                        width: 2
                    },
                },
                {
                    name: '交界性逸博',
                    type: 'bar',
                    stack: 'total',
                    color: ' #fc5252',
                    yAxisIndex: 1,
                    // areaStyle: {},
                    lineStyle: {
                        width: 1
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: [],
                    lineStyle: {
                        width: 2
                    },
                },
                {
                    name: '室性逸博',
                    type: 'bar',
                    stack: 'total',
                    color: '#F0B27A ',
                    yAxisIndex: 1,
                    // areaStyle: {},
                    lineStyle: {
                        width: 1
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: [],
                    lineStyle: {
                        width: 2
                    },
                },
                {
                    name: '房颤',
                    type: 'bar',
                    stack: 'total',
                    yAxisIndex: 1,
                    color: '#A6ACAF ',
                    // areaStyle: {},
                    lineStyle: {
                        width: 1
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: [],
                    lineStyle: {
                        width: 2
                    },
                },

            ]
        },
        this.Calendar_option = {
            // tooltip: {},
            visualMap: [
                {
                    min: -15,
                    max: 40,
                    seriesIndex: [0],
                    inRange: {
                        color: ['#FFFFFF', '#e7efff', '#b2caf9'],
                    },
                    text: ['多', '检测次数：少'],
                    orient: 'horizontal',
                    top: 0,
                    itemHeight: 80,
                    itemWidth: 15,
                    textStyle: {
                        width: 80,
                        height: 100,
                        overflow: "break"
                    }
                },
                {
                    pieces: [
                        { value: 0, label: '健康' },
                        // {value:0,label:'健康'},
                        { gt: 0, lte: 0.5, label: '亚健康' },
                        { gt: 0.5, label: '异常' }
                    ],
                    textGap: 5,
                    itemGap: 5,
                    // text: ['高', '疑似度：低'],
                    seriesIndex: [1],
                    itemSymbol: 'circle',
                    left: 'center',
                    orient: 'horizontal',
                    top: 25,
                    itemHeight: 80,
                    itemWidth: 12,
                    textStyle: {
                        width: 80,
                        height: 100,
                        overflow: "break"
                    },
                    inRange: {
                        color: ['#FFFFFF', '#F1948A ', '#B03A2E'],

                    },
                },

            ],
            calendar: {
                orient: 'vertical',
                left: 50,
                top: 110,
                cellSize: this.cellSize ,
                // range: ['2022-07-01', '2022-08-31'],//返回有数据的月份
                range: this.year,
                monthLabel: {
                    nameMap: 'ZH',    // v5.2.2 及以后版本
                    margin: 15,
                },
                dayLabel: {
                    nameMap: 'ZH'    // v5.2.2 及以后版本
                },
                yearLabel: {
                    color: '#424949',
                },
            },
            series: [
                {
                    type: 'heatmap',
                    coordinateSystem: 'calendar',
                    data: []
                    // this.arrayCalendar
                },
                {
                    type: 'scatter',
                    coordinateSystem: 'calendar',
                    symbolSize: function (val) {  //标志大小随着值变化
                        return val[1] * 12
                    },
                    data: []
                },
                {
                    id: 'label',
                    type: 'scatter',
                    coordinateSystem: 'calendar',
                    symbolSize: 0,
                    label: {
                        show: true,
                        formatter: function (params) {
                            return echarts.time.format(params.value[0], '{dd}', false);
                        },
                        // offset: [-this.cellSize[0] / 2 + 7, -this.cellSize[1] / 2 + 7],
                        fontSize: 8
                    },
                    data: []
                },
            ]

        }

    },
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {
        this.getWeekDay()
        this.initCalendar()
        this.Calendar_queryCount()
        window.onresize = () => {
            return (() => {
                window.screenHeight = window.innerHeight;
                this.screenHeight = window.screenHeight;
                window.screenWidth = window.innerWidth;
                this.screenWidth = window.screenWidth;
            })();
        };

    },
    beforeCreate() { }, //生命周期 - 创建之前
    beforeMount() { }, //生命周期 - 挂载之前
    beforeUpdate() { }, //生命周期 - 更新之前
    updated() { }, //生命周期 - 更新之后
    beforeDestroy() { }, //生命周期 - 销毁之前
    destroyed() { }, //生命周期 - 销毁完成
    activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>
.calender-scroll {
    overflow-y: scroll;
    height: 550px;
}

.calender-scroll::-webkit-scrollbar {
    /*滚动条高宽度*/
    width: 8px;
    height: 4px;
}

.calender-scroll::-webkit-scrollbar-thumb {
    /*滚动条滑块*/
    border-radius: 3px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #c7c4c4;
}

.calender-scroll::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    border-radius: 10px;
    background: #ededed;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, .1);
}

.timeStyle {
    color: #409EFF;
}

.blank {
    border-radius: 4px;
    height: 20px;
}
</style>